import React from 'react'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import LangImg from '../../assets/image/svg/lang.inline.svg'
import { Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import * as userActions from '../../store/actions/userActions'

const Select = loadable(() => import('../Core/Select'))

const langs = [
  // first option in this array is the default
  { value: 'en', region: 'en-US', label: 'English' },
  { value: 'es', region: 'es-MX', label: 'Español' },
]

const LangSelector = props => {
  const { t } = useTranslation()
  const { siteTitle, white } = props
  const csrfCoinigy = useSelector(state => state.user.csrf_token)
  const csrfCookie = useSelector(state => state.user.csrfCookie)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  // const userSettings = useSelector(state => state.user.user_settings);
  // const browserLang = useSelector(state => state.user.browserLang);
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''

  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  let i18nLang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
    // dispatch(userActions.saveBrowserLang(i18n.language));
  }

  const result =
    typeof langs.find(({ value }) => value === i18nLang) !== 'undefined'
      ? langs.find(({ value }) => value === i18nLang)
      : { value: 'en', region: 'en-US', label: 'English' }

  const handleNavigationChange = lng => {
    let pathName = window.location.pathname
    var pathArr = window.location.pathname.split('/').filter(function (v) {
      return v
    })
    if (pathArr[0] !== i18n.language) {
      if (pathName.includes(`/${pathArr[0]}/`)) {
        navigate(pathName.replace(`/${pathArr[0]}/`, `/${lng}/`))
      }
    }
  }

  const handleLangChange = (lng, reg) => {
    if (csrfCookie !== csrfCoinigy) {
      dispatch(userActions.fetchCsrf())
    }
    if (userSessionExpired === false) {
      dispatch(userActions.saveUserLang(newToken, lng, reg))
    }
    i18n.changeLanguage(lng)
    handleNavigationChange(lng)
  }

  // if (userSessionExpired === false && userSettings.language !== lang) {
  //   dispatch(userActions.saveUserLang(csrfCoinigy, lang, lang === 'en' ? 'en-US' : 'es-MX'));
  // }

  return (
    <Row className="mx-0">
      {white ? (
        <LangImg
          width="16"
          height="40"
          style={{ filter: 'invert(80%)' }}
          className={`mr-1`}
          aria-hidden="true"
        />
      ) : (
        <LangImg
          width="16"
          height="40"
          style={{ filter: 'invert(20%)' }}
          className={`mr-1`}
          aria-hidden="true"
        />
      )}
      <Select
        className="languages"
        aria-label={t('language')}
        userDarkMode={white}
        isSearchable={false}
        options={langs}
        defaultValue={
          i18n.language
            ? {
                value: result ? result.value : '',
                label: result ? result.label : '',
              }
            : langs[0]
        }
        onChange={option => handleLangChange(option.value, option.region)}
        components={{ IndicatorSeparator: () => null }}
      />
    </Row>
  )
}

export default LangSelector
